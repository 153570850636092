import * as PropTypes from 'prop-types';
import * as React from 'react';
import Loadable from 'react-loadable';
import {connect} from 'react-redux'
import {withRouter} from 'react-router';
import {Route, Switch} from 'react-router-dom';

import * as ParamsStore from 'stores/Params';

function Loading(props) {
    const {error} = props;
    if (error && location.hostname !== 'localhost') {
        location.reload(true);
    }
    return null;
}

Loading.propTypes = {
    error: PropTypes.any,
};

const Sso = Loadable({loader: () => import('pages/Sso'), loading: Loading});

@connect(state => ({}), ParamsStore.actionCreators)
class BlankLayout extends React.Component {
    static propTypes = {
        ...ParamsStore.ActionShape,
    };

    componentDidMount() {
        const {location: {pathname, search}} = this.props;

        dataLayer.push({
            page: pathname + search,
        });
    }

    render() {
        return (
            <Switch>
                <Route path="/sso/:app/:action"><Sso /></Route>
            </Switch>
        )
    }
}

export default withRouter(BlankLayout);
